.primaryBtn {
  margin-top: 30px;
  width: 15rem;
  position: relative;
  height: 55px;
  background: linear-gradient(
      180deg,
      #72f138 -98.76%,
      rgba(114, 241, 56, 0) 134.37%
    ),
    #72f138;
  box-shadow: 0px 0px 5px rgba(98, 255, 95, 0.8),
    0px 0px 30px rgba(98, 255, 95, 0.3), inset 0px 0px 12px #5fff6f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryBtn p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #002800;
}

.primaryBtn:disabled {
  background: #5f7381;
  box-shadow: none;
}

.inputAd {
  height: 46px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #72f138;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 51px;
  border-width: 0;
  border-color: none;
}

.dicecontainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  margin: 10px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #10121B;
  border-radius: 10px;
  padding: 20px;
  width: auto;
}

.hrContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hrWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
  width: 40px;
}

.hrWrapper h1 {
  position: relative;
  font-weight: 800;
  font-size: 1.3125rem;
  line-height: 1.8125rem;
  color: #f8f8ff;
}

.hrImg {
  position: relative;
}

.slider-container {
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  position: relative;
  appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #ddd;
  outline: none;
  margin-right: 10px;
}

.slider::-webkit-slider-runnable-track {
  height: 5px;
  border-radius: 5px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: linear-gradient(180deg, #affc73 0%, #72f138 100%);
  box-shadow: 0px 0px 30px rgba(156, 207, 240, 0.4);
  border-radius: 50%;
  margin-top: -10px;
}

.betWindow {
  position: relative;
  padding: 15px 23px;
  width: 100%;
  height: 100%;
  margin-top: 39px;
  /* flex-wrap: wrap; */
  background: #252838;
  box-shadow: 0px 6px 0px #1f262e;
  border-radius: 12px;
  box-sizing: border-box;
}

.betWrapper {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

.betWindow h3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5f7381;
}

.betInputWrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
  background: #212432;
  box-shadow: inset 0px -4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.betInputWrapper img {
  position: absolute;
  left: 10px;
}

.betInputWrapper input {
  color: #f8f8ff;
  /* width: 100%; */
}

.btnInputWrapper {
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  right: 10px;
}

.btnInput {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: #353749;
  border-radius: 4px;
  transition: 0.2s;
}

.btnInput:hover {
  opacity: 0.5;
}

.btnInput p {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #5f7381;
}

@media (max-width: 1400px) {
  .bg {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .betWindow {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .betWrapper {
    justify-content: center;
  }
  .dicecontainer {
    align-items: flex-start;
  }
}

.betAmountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.balanceAmount {
  color: #B1BAD3;
  font-size: 12px;
  font-weight: bold;
}
