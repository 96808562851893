@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap");

*,
::after,
::before {
  margin: 0;
  box-sizing: border-box;
  caret-color: transparent;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Jost", sans-serif;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
  width: 100%;
  height: 100%;
}

body {
  background-color: #121820;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  outline: none;
  border: none;
  background: none;
  color: #f8f8ff;
  caret-color: #f8f8ff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:active {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-family: "Jost", sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
}
