.wrapper {
  width: 5.625rem;
  height: 5.625rem;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.wrapper:enabled:hover {
  opacity: 0.5;
}

.wrapper img {
  width: 100%;
  height: 100%;
}

.bombgif {
  position: absolute;
  transform: translate(0, -90px);
  z-index: 2;
}

@media (max-width: 1200px) {
  .wrapper {
    width: 5.325rem;
    height: 5.325rem;
  }
}

@media (max-width: 590px) {
  .wrapper {
    width: 4.125rem;
    height: 4.125rem;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.7); /* Adjust scale value to control the intensity of the pulse */
  }
}

.pulsingSVG {
  animation: pulse 0.2s ease-in-out infinite;
}

@keyframes hoverAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.hoverSquare {
  animation: hoverAnimation 0.5s ease-in-out;
}