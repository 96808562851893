.diceAnimWrapper {
    position: absolute;
    width: 80px;
    height: 60px;
    bottom: 10px;
}

.diceAnimGifWrapper {
    top: -10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
}

.diceReact {
    width: 100%;
    position: absolute;
}

.ticketData {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-weight: 700;
}

.ticketDataWin {
    color: #51D231;
}

.ticketDataLose {
    color: #C53111;
}