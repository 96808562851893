.plinko-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.plinko {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  gap: 10px;
}

.plinko-wrapper {
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  max-width: 900px;
  border-radius: 12px;
  padding: 0;
}

.plinko-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

@media only screen and (min-width: 901px) {
  .plinko-container {
    align-items: center;
  }
  .plinko {
    gap: 20px;
  }
  .plinko-wrapper {
    flex-direction: row;
    padding: 20px;
  }
}
