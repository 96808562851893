.wrapper {
  padding: 20px;
}

.wrapper,
.wrapperGame {
  z-index: 1;
  position: relative;
  background: #252838;
  box-shadow: 0px 6px 0px #1f262e;
  border-radius: 12px;
}

.wrapper h3 {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #f8f8ff;
}

.inputWrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
  background: #212432;
  box-shadow: inset 0px -4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.wrapperGame img,
.inputWrapper img {
  width: 26px;
  height: 26px;
}

.inputWrapper img {
  position: absolute;
  left: 15px;
}

.btnWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  margin-top: 11px;
}

.wrapperGame {
  padding: 31px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.balanceWrapper {
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 21px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.balance {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.balanceLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wrapperGame h4 {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #5f7381;
}

.balanceLine h3 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #72f138;
}

.balance {
  width: 100%;
}

.multiplier {
  font-weight: 700;
  font-size: 3.625rem;
  line-height: 5rem;
  background: linear-gradient(180deg, #d3ff76 0%, #72f138 46.4%, #3bbd1b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 5px rgba(98, 255, 95, 0.8),
    0px 0px 30px rgba(98, 255, 95, 0.3);
}

.inputAd {
  height: 46px;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #72f138;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 51px;
  border-width: 0;
  border-color: none;
}

.btnInputWrapper {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.btnInput {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: #353749;
  border-radius: 4px;
  transition: 0.2s;
}

.btnInput:hover {
  opacity: 0.5;
}

.btnInput p {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #5f7381;
}

.primaryBtn {
  height: 55px;
  background: linear-gradient(
      180deg,
      #72f138 -98.76%,
      rgba(114, 241, 56, 0) 134.37%
    ),
    #72f138;
  box-shadow: 0px 0px 5px rgba(98, 255, 95, 0.8),
    0px 0px 30px rgba(98, 255, 95, 0.3), inset 0px 0px 12px #5fff6f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryBtn p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #002800;
}

.rowBtn,
.rowBtnActive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  border-radius: 8px;
  max-width: 45px;
  text-transform: capitalize;
}

.rowBtn {
  background: #2f3245;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #5f7381;
}

.rowBtnActive {
  background: #353749;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #72f138;
}

/* @media (max-width: 1200px) {
  .wrapper,
  .wrapperGame {
    max-width: 340px;
  }
} */

.betAmountContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.balanceAmount {
  color: #B1BAD3;
  font-size: 12px;
  font-weight: bold;
}