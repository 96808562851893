.towers-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.towers-inner {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.towers-mob {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  min-width: 340px;
}

.towers {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  max-width: 1050px;
  border-radius: 10px;
  padding: 20px 20px 0 20px;
  overflow: hidden;
}

.towers-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.animation-wrapper {
  position: relative;
  max-width: 100%;
}

.towers-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #252838;
  border-radius: 18px 18px 0px 0px;
  padding: 60px 20px 0 20px;
  min-width: 300px;
  min-height: 420px;
}

.towers-bottom {
  max-width: 100%;
}

.towers-layer, .towers-bottom, .towers-field-button {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media only screen and (min-width: 1001px) {
  .towers-inner {
    width: 100%;
  }
  .towers-container {
    align-items: center;
  }
  .towers-wrapper {
    padding-bottom: 16px;
  }
  .towers {
    flex-direction: row;
  }
}
